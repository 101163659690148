import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import React from "react";

function App() {
  const Home = React.lazy(() => import("./pages/Home"));
  const News = React.lazy(() => import("./pages/News"));
  const About = React.lazy(() => import("./pages/About"));
  const Company = React.lazy(() => import("./pages/Company"));
  const Accordions = React.lazy(() => import("./pages/Accordion"));
  const Error = React.lazy(() => import("./pages/Error"));
  const PrivacyApp = React.lazy(() => import("./pages/PrivacyApp"));
  const Privacy = React.lazy(() => import("./pages/PrivacyWebsite"));
  const TeamsConditions = React.lazy(() => import("./pages/TeamsConditions"));
  const TeamsConditionsApp = React.lazy(() => import("./pages/TeamsConditionsApp"));
  const Resource = React.lazy(() => import("./pages/Resource"));
  const Mission = React.lazy(() => import("./pages/Mission"));
  const Ceo = React.lazy(() => import("./pages/Ceo"));
  const Contact = React.lazy(() => import("./pages/Contact"));
  const Login = React.lazy(() => import("./backend/login"));
  const Dashboard = React.lazy(() => import("./backend/Dashboard"));
  const FAQ = React.lazy(() => import("./backend/FAQ"));
  const ResourceUpload = React.lazy(() => import("./backend/Resource"));
  const Notification = React.lazy(() => import("./backend/Notification"));
  const Careers = React.lazy(() => import("./pages/Careers"));
  const Investors = React.lazy(() => import("./pages/Investors"));
  const Actson = React.lazy(() => import("./pages/Actson"));
  const Sitemap = React.lazy(() => import("./pages/Sitemap"));
  const Explainer = React.lazy(() => import("./pages/Explainer"));

  const user = JSON.parse(sessionStorage.getItem('user'))

  return (
    <Router>
      <React.Suspense fallback={
        <div className="preloader">
          <div className="placeholder">
            <div className="loader">
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        </div>}>
        <Switch>
          <Route
            exact path={`${process.env.PUBLIC_URL + "/privacy"}`} component={PrivacyApp}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/explainer"}`} component={Explainer}
          />
			<Route
            exact path={`${process.env.PUBLIC_URL + "/tc"}`} component={TeamsConditionsApp}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} component={Privacy}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/terms-conditions"}`} component={TeamsConditions}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/actson"}`} component={Actson}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/sitemap"}`} component={Sitemap}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/about"}`} component={About}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/investors"}`} component={Investors}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/careers"}`} component={Careers}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/news"}`} component={News}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/vision"}`} component={Mission}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/ceo"}`} component={Ceo}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/resource"}`} component={Resource}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/ideaclip"}`} component={Company}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/faq"}`} component={Accordions}
          />
          {/* <Route
          exact path={`${process.env.PUBLIC_URL + "/pricing"}`} component={Pricing}
        /> */}
          <Route
            exact path={`${process.env.PUBLIC_URL + "/error"}`} component={Error}
          />
          {/* <Route
          exact path={`${process.env.PUBLIC_URL + "/blog-list"}`} component={Bloglist}
        />
        <Route
          exact path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={Blogdetails}
        /> */}
          <Route
            exact path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/home"}`} component={Home}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/admin/login"}`} component={Login}
          />
          <Route
            exact path={`${process.env.PUBLIC_URL + "/admin"}`} component={Login}
          />
          {user && <Route
            exact path={`${process.env.PUBLIC_URL + "/admin/dashboard"}`} component={Dashboard}
          />}
          {user && <Route
            exact path={`${process.env.PUBLIC_URL + "/admin/FAQ"}`} component={FAQ}
          />}
          {user && <Route
            exact path={`${process.env.PUBLIC_URL + "/admin/Resource"}`} component={ResourceUpload}
          />}
           {user && <Route
            exact path={`${process.env.PUBLIC_URL + "/admin/Notification"}`} component={Notification}
          />}

          <Redirect from="/*" to="/" />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
